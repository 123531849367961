.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 101;
}

.modal-content {
    min-width: 50vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 4px;
    outline: none;

    .modal-header {
        padding: 20px;
        border-bottom: 1px solid rgb(207, 207, 207);
        font-weight: 600;

        .icon {
            float: right;
        }
    }
    .modal-main-content {
        padding: 20px;
    }
}
