$blog-breakpoint: large;

.blog-title {
  max-width: 1300px;
  margin: auto;
}

// landing page
.blog {

  max-width: 1300px;
  margin: auto;

  // give all images in blog border radius and make them go full width
  img {
    border-radius: 10px;
    width: 100%;
  }

  .blog__item {
    @include responsive-padding(x, $multiplier: 0.5);
    @include responsive-padding(y, $scale: $spacing-small);
    @include responsive-margin(top, $scale: $spacing-small);

    background: $white;
    border: 1px solid $light-grey;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    &:first-child {
      margin-top: 0;
    }

    @include breakpoint(above, $blog-breakpoint) {
      align-items: flex-start;
      flex-direction: row;
    }
  }

  .blog-date {
    @include responsive-margin(right, $multiplier: 0.5, $from: $blog-breakpoint);
    @include responsive-padding(right, $multiplier: 0.5, $from: $blog-breakpoint);

    position: relative;
    margin-bottom: 25px;

    @include breakpoint(above, $blog-breakpoint) {
      margin-bottom: 0;
      // make sure there's enough width for read more button
      min-width: 210px;
    }

    &::after {
      @include breakpoint(above, $blog-breakpoint) {
        content: '';
        background: $green;
        width: 3px;
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
      }
    }

    .blog-date__month {
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 0.15em;
    }

    .blog-date__day {
      color: $green;
      font-size: 50px;
      font-weight: 700;
      line-height: 1em;

      @include breakpoint(above, $blog-breakpoint) {
        font-size: 100px;
      }
    }
  }

  .blog-content {
    @include breakpoint(above, $blog-breakpoint) {
      min-height: 200px; // make sure there's enough height for read more button
    }

    .read-more {
      @include responsive-position(left, $multiplier: 0.5, $from: $blog-breakpoint);
      @include responsive-position(bottom, $scale: $spacing-small, $from: $blog-breakpoint);

      @include breakpoint(above, $blog-breakpoint) {
        position: absolute;
      }
    }

    .advice-body {
      * {
        font-family: $open-sans !important;
        color: $dark-grey !important;
        font-size: 19px !important;
        line-height: 26px !important;
      }

      a {
        color: $green !important;
        * {
          color: $green !important;
        }
      }

      .table {
        min-width: 100%;
        margin: 20px 0;
        &.table-bordered {
          border: 1px solid $grey;
        }

        thead {
          tr {
            th {
              border: 1px solid $grey;
              border-bottom-width: 2px;
              font-weight: bold;
            }
          }
        }
        tbody {
          tr {
            border: 1px solid $grey;

            th {
              border: 1px solid $grey;
              border-bottom-width: 2px;
              font-weight: bold;
            }

            td {
              border: 1px solid $grey;
            }
          }
        }
      }
    }
  }
}

// post page
.blog--list {

}
