.agent-detail {
  .image-holder, .contact-info, .rating-holder {
    float: left;
    box-sizing: border-box;
    @include breakpoint(below, small) {
      //display: block;
      //float: none;
      //width: 100% !important;
    }
  }

  .image-holder {
    width: 176px;
    height: 176px;
    margin-right: 50px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-size: cover;

    @include breakpoint(below, large) {
      width: 70px !important;
      height: 70px;
      margin-right: 10px;
    }
  }

  .contact-info {
    width: calc(60% - 113px);

    h1 {
      margin-top: 0;
    }

    .display-table {
      display: table;
      width: 100%;
      table-layout: fixed;
      .display-table-row {
        display: table-row;
        .display-table-cell {
          display: table-cell;

          .lender-name {
            margin-right: 50px;
          }

          .hover-pop-up {
            display: inline-block;
            margin-right: 20px;
            top: 5px;

            .hover-pop-up__icon {
              flex: none;
              display: inline-block;
              width: 20px;
            }
          }

          .equal-housing {
            img {
              display: inline-block;
              vertical-align: bottom;
              max-width: 30px;
            }
          }

          &:first-child {
            width: 30%;
            font-weight: bold;
            color: $blue;
            line-height: 39px;
          }

          &.social-profiles {
            vertical-align: middle;
            .social-profile-link {
              font-size: 30px;
              margin-right: 16px;
              color: #2c2c2c;
            }
          }
        }

        &.social-profiles-row > * {
          padding-top: 16px;
        }
      }

    }
    .small-text {
      font-size: 15px;
      display: inline-block;
      margin-top: 15px;

      &:first-of-type {
        margin-top: 30px;
      }
    }

    @include breakpoint(below, large) {
      width: calc(100% - 80px);

      .display-table {
        font-size: 15px;

        .display-table-row {
          margin-bottom: 10px;
          display: block !important;
          line-height: normal !important;

          .display-table-cell {
            display: block;
            width: 100% !important;
            line-height: normal !important;
          }
        }
      }
    }
  }

  .rating-holder {
    width: calc(40% - 113px);
    text-align: right;
    .officer {
      display: inline-block;
      margin-right: 15px;

      .stars {
        flex: none;
        display: inline-block;
        .star {
          display: inline-block;
          font-size: 26px;
          margin-right: 10px;
        }
      }
      p {
        text-align: left;
        font-size: 14px;
        font-style: italic;
        a {
          color: $blue;
          font-weight: bold;
        }

      }
      margin-bottom: 50px;
    }

    .hover-pop-up {
      display: inline-block;
      top: -33px;
      margin-left: 30px;

      .hover-pop-up__icon {
        width: 20px;
      }

    }

    @include breakpoint(below, large) {
      width: calc(100% - 80px);
      float: right;
      text-align: left;

      .officer {
        margin-bottom: 15px;
        margin-right: 15px;
        .stars {
          .star {
            font-size: 16px;
          }
        }
      }

      .hover-pop-up {
        //float: right;
        top: -26px;
        margin-left: 10px;

        .hover-pop-up__icon {
          width: 20px;
        }

      }
    }

  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.agent-info {
  background: none;
  border: none;

  .back-btn-wrapper {
    margin-bottom: 100px;

    .back {
      background: none;
      color: $green;
      display: inline;
      overflow: visible;
      padding: 0;

      &::after {
        display: none;
      }

      &::before {
        @include icomoon;
        content: '\e904';
        display: inline-block;
        margin-right: 20px;
        transition: transform 0.3s;
        transform: rotate(180deg);
      }

      &:hover,
      &:focus {
        &::before {
          transform: rotate(180deg) translateX(5px);
        }
      }
    }
  }
}
