.form {
    .form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .form-item {
            flex-grow: 1;
            margin-bottom: 20px;

            &.col-2, .col-2 {
                flex-grow: 0;
                width: calc(50% - 8px);
            }
            &.col-3, .col-3 {
                flex-grow: 0;
                width: calc(33% - 8px);
            }

            .radio-group {
                display: flex;
                flex-direction: row;
            }

            & > .label {
                margin-bottom: 8px;
            }
        }

        & > .help-text {
            font-size: 12px;
        }
        .recaptcha {
            margin-bottom: 20px;
        }
        @include breakpoint(below, small) {
            flex-wrap: wrap;
            .form-item {
                width: 100%;
                &.col-2, &.col-3, .col-2, .col-3 {
                    width: 100%;
                }
                .radio-group {
                    flex-wrap: wrap;
                    .checkbox-and-radio-container {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.survey-success {
    text-align: center;
    p {
      font-size: 16px;
    }

    @include breakpoint(below, small) {
      p {
        font-size: 13px;
      }
    }
  }

.survey-error {
    text-align: center;

    h1 {
        color: $red;
    }
    p {
        font-size: 17px;
    }

    @include breakpoint(below, small) {
        h1 {
            font-size: 32px;
        }
        p {
            font-size: 13px;
        }
    }
}

.text-field {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    .label {
        width: 100%;
        margin-bottom: 8px;
    }
    .input, textarea {
        margin-bottom: 0;
    }
    .help-text {
        font-size: 12px;
    }
    .error-message {
        color: red;
        font-size: 14px;
        width: 100%;
    }

    &.invalid {
        input, textarea {
            border-color: red;
        }
    }
}

.checkbox-and-radio-container {
    margin-bottom: 8px;

    label {
        margin-left: 4px;
    }
}

.rating-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .stars {
        font-size: 22px;
        .star:hover {
            color: $star-filled-in;
          }
    }
    .star:not(:last-child) {
        margin-right: 12px;
    }

    .help-text {
        font-size: 12px;
    }
}

.radio-group~.error-message, .rating-input .error-message, .select-container .error-message {
    color: red;
    font-size: 14px;
    width: 100%;
}

.select-container.invalid {
    .select-value, .select-options {
        border-color: $red;
    }
}
