// title font is Open Sans Condensed
// light (300) and bold (700) are included
$open-sans-condensed: 'Open Sans Condensed', sans-serif;

// body font is open sans
// regular (400), semibold (600), and bold (700) are included
$open-sans: 'Open Sans', sans-serif;

body {
  color: $dark-grey;
  font-family: $open-sans;
  font-size: 18px;
  line-height: 1.3em;

  @include breakpoint(above, small) {
    font-size: 19px;
  }
}

// spacing between typical in-page elements
p + p,
p + ul,
ul + p,
p + img,
img + p {
  margin-top: 1.5em;
}

a,
a:link,
a:visited {
  color: $green;
  text-decoration: none;
  transition: color 0.3s;

  &:hover,
  &:focus {
    color: $dark-green;
  }
}

// ////
//  Heading 1
// ////
@mixin h1 {
  @include responsive-margin(bottom, $scale: $below-h1);

  color: $blue;
  font-family: $open-sans-condensed;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.1em;

  a,
  a:link,
  a:hover {
    color: inherit;

    &:hover,
    &:focus {
      color: $dark-green;
    }
  }

  @include breakpoint(above, x-small) {
    font-size: 40px;
  }

  @include breakpoint(above, small) {
    font-size: 45px;
  }

  @include breakpoint(above, large) {
    font-size: 50px;
  }

  @include breakpoint(above, x-large) {
    font-size: 55px;
  }

  @include breakpoint(above, xx-large) {
    font-size: 65px;
  }
}

h1,
.h1 {
  @include h1;
}

// ////
//  Heading 2
// ////
@mixin h2 {
  @include responsive-margin(bottom, $scale: $below-h2);

  color: $blue;
  font-family: $open-sans-condensed;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.1em;

  a,
  a:link,
  a:hover {
    color: inherit;

    &:hover,
    &:focus {
      color: $dark-green;
    }
  }

  @include breakpoint(above, small) {
    font-size: 35px;
  }

  @include breakpoint(above, x-large) {
    font-size: 40px;
  }
}

h2,
.h2 {
  @include h2;
}

// ////
//  Heading 3
// ////
@mixin h3 {
  @include responsive-margin(bottom, $scale: $below-h3);

  color: $green;
  font-family: $open-sans-condensed;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.1em;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  a,
  a:link,
  a:hover {
    color: inherit;

    &:hover,
    &:focus {
      color: $dark-green;
    }
  }

  @include breakpoint(above, medium) {
    font-size: 25px;
  }
}

h3,
.h3 {
  @include h3;
}

// ////
//  Heading 4
// ////
@mixin h4 {
  @include responsive-margin(bottom, $scale: $below-h4);

  color: $blue;
  font-family: $open-sans-condensed;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.1em;

  a,
  a:link,
  a:hover {
    color: inherit;

    &:hover,
    &:focus {
      color: $dark-green;
    }
  }

  @include breakpoint(above, small) {
    font-size: 25px;
  }
}

h4,
.h4 {
  @include h4;
}

// ////
//  Heading 5
// ////
@mixin h5 {
  @include responsive-margin(bottom, $scale: $below-h5);

  color: $blue;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2em;

  a,
  a:link,
  a:hover {
    color: inherit;

    &:hover,
    &:focus {
      color: $dark-green;
    }
  }

  @include breakpoint(above, small) {
    font-size: 19px;
  }
}

h5,
.h5 {
  @include h5;
}

// ////
//  Intro Text
// ////
.intro {
  font-size: 20px;
  line-height: 1.2em;

  @include breakpoint(above, small) {
    font-size: 23px;
  }
}
