ol.numbered-list {
  list-style: none;
  counter-reset: numbered-list; // reset the counter for everylist

  li {
    padding-left: 55px;
    position: relative;

    + li {
      margin-top: 25px;

      @include breakpoint(above, small) {
        margin-top: 35px;
      }
    }

    &::before {
      counter-increment: numbered-list; // Increment the value of counter by 1
      content: counter(numbered-list); // grab the current value of counter
      background: $green;
      border-radius: 50%;
      color: $white;
      height: 40px;
      font-weight: 700;
      left: 0;
      line-height: 40px;
      position: absolute;
      text-align: center;
      top: auto;
      width: 40px;

      @include breakpoint(above, small) {
        font-size: 24px;
      }
    }
  }
}

