.title-aside {
  display: flex;
  align-items: top;
  flex-direction: column;

  @include breakpoint(above, medium) {
    flex-direction: row;
  }

  .title-aside__title {
    @include responsive-padding(right, $multiplier: .5, $from: medium);
    // @include breakpoint(above, medium) {
    //   width: calc(100% - 900px);
    // }
  }

  .title-aside__body {
    max-width: 900px;
  }

  + .title-aside {
    @include responsive-margin(top, $scale: $spacing-small, $from: medium);

    margin-top: 35px;
  }
}
