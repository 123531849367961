.single-column {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.twoColWrapper {
  @include columns-wrapper($two-column-split);

  .multiColInner {
    @include columns(2);
  }
}

.threeColWrapper {
  @include columns-wrapper($three-column-split);

  .multiColInner {
    @include columns(3);
  }
}

.fourColWrapper {
  @include columns-wrapper($four-column-split-one);

  .multiColInner {
    @include columns(4);
  }
}
