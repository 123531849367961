.social-sharing {
  margin-top: 40px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  > div:not(:last-child) {
    margin-right: 10px;

    @include breakpoint(above, large) {
      margin-right: 20px;
    }
  }

  .SocialMediaShareButton {
    color: $medium-grey;
    text-align: center;

    span {
      border: 1px solid transparent;
      border-radius: 50%;
      display: block;
      height: 30px;
      line-height: 28px;
      transition: color 0.3s, border 0.3s;
      width: 30px;
    }

    &:hover,
    &:focus {
      span {
        border-color: $dark-grey;
        color: $dark-grey;
      }
    }
  }

  // TODO: Remove in new build
  a,
  a:link,
  a:visited {
    color: $medium-grey;
    text-align: center;

    span {
      border: 1px solid transparent;
      border-radius: 50%;
      display: block;
      height: 30px;
      line-height: 28px;
      transition: color 0.3s, border 0.3s;
      width: 30px;
    }

    &:hover,
    &:focus {
      span {
        border-color: $dark-grey;
        color: $dark-grey;
      }
    }
  }

  .social-sharing__text {
    font-size: 15px;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 10px;

    @include breakpoint(above, x-small) {
      width: auto;
      margin-bottom: 0;
    }
  }
}
