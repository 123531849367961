@charset 'utf-8';

/// Provides a quick method for setting an element's position. Use a `null`
/// value to 'skip' a side.
///
/// If `$position` is not provided, only the directonal properties will be set.
///
/// @group Layout
///
/// @source https://github.com/thoughtbot/bourbon/blob/bea12e6836c2f2b85f269fcb3a046f221236d554/core/bourbon/library/_position.scss
///
/// ----------------------------------------------------------------------------
///
/// @param {string} $position [relative]
///   CSS position value, e.g. `relative`, `absolute`, `fixed`
///
/// @param {list} $coordinates [null]
///   List of lengths, defined as CSS shorthand.
///
/// ----------------------------------------------------------------------------
///
/// @example scss - Passing a position-type and coordinates
///   .element {
///     @include position(absolute, 0 null null 10em);
///   }
///
/// @example css
///   .element {
///     position: absolute;
///     top: 0;
///     left: 10em;
///   }
///
/// ----------------------------------------------------------------------------
///
/// @example scss - Passing only coordinates
///   .element {
///     @include position(0 null null 10em);
///   }
///
/// @example css
///   .element {
///     top: 0;
///     left: 10em;
///   }

@mixin position(
  $position,
  $coordinates: null
) {
  @if type-of($position) == 'list' {
    $coordinates: $position;
    $position: null;
  }

  $coordinates: _unpack($coordinates);

  $offsets: (
      top: nth($coordinates, 1),
      right: nth($coordinates, 2),
      bottom: nth($coordinates, 3),
      left: nth($coordinates, 4),
  );

  @if $position {
    position: $position;
  }

  @each $offset, $value in $offsets {
    @if _is-length($value) {
      #{$offset}: $value;
    }
  }
}
