$cookie-breakpoint: 'large';


.cookie-consent {
  background: $blue;
  color: $white;
  padding: 0 5% 0 5%;
  position: fixed;
  transition: padding 0.3s;
  min-height: 77px;
  width: 100%;
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  @include breakpoint(above, $cookie-breakpoint) {
    flex-direction: row;
  }

  .accept-button {
      color: $white;
      background: $green;
      display: block;
      font-family: $open-sans-condensed;
      font-weight: 700;
      max-height: none;
      overflow: hidden;
      text-decoration: none;
      text-transform: uppercase;
      transition: color 0.3s, max-height 0.3s;
      border: none;
      font-size: 18px;
      line-height: 2.0em;
      border-radius: 5px;
      padding: 0 17px;

      &:hover,
      &:focus {
        color: $white;
        background: $dark-green;
      }

      @include breakpoint(above, $cookie-breakpoint) {
        font-size: 18px;
        margin-left: 40%;
      }
    }
  }

// cookie consent open at mobile
@include breakpoint(below, $cookie-breakpoint) {
  .cookie-consent {
    padding: 15px 5px;
    max-height: 500px;

    .accept-button {
      margin-top: 5px;
      max-height: 50px;
    }
  }
}
