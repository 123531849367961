//Loader Image
.loader-image {
  max-height: 150px;
  max-width: 150px;
}

.loader-wrapper {
  min-height: 400px;
  padding-top: 115px;
}
