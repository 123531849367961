//collection of helpful utility classes
// different colored backgrounds
.blue-bg {
  background: $light-blue;
}

.green-bg {
  background: $green;
}

.dark-green-bg {
  background: $dark-green;
}

// text colors
.white {
  color: $white !important;
}

// text aligns
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.mb80 {
  margin-bottom: 80px;
}

.mt36 {
  margin-top: 36px;
}

// Shahid

.container {
  padding: 20px 10px;
  margin-top: 20px;
  background: #fff;
  border: 1px solid #dadada;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.buttons-holder {
  &.inline {

    li {
      display: inline-block;
      vertical-align: top;
      margin-right: 20px;

    }
  }

  @include breakpoint(below, small) {
    li {
      display: block !important;
      margin-right: 0 !important;
    }
  }
}

.process-holder {
  .button {
    display: inline-block;
    min-width: 350px;
    margin-bottom: 20px;

    @include breakpoint(below, small) {
      min-width: auto;
      width: 100%;
      min-height: 60px;
    }
  }

  input, textarea {
    max-width: 350px;
  }
  .error-message-wrapper {
    margin: 0 auto;
    max-width: 350px;
  }
}

.youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  .video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.search-legal-term {
  font-size: 12px;
  line-height: 15px;
  margin-top: 20px;
}

.search-radius {
  -webkit-border-radius: 10px 0 0 0 !important;
  -moz-border-radius: 10px 0 0 0 !important;
  border-radius: 10px 0 0 0 !important;
}

//how-it-works
.how-it-works {
  .steps {
    .steps__step {
      .steps-desc {
        margin-top: 20px;
        text-align: left;

        .steps-desc__number {
          color: $green;
          display: block;
          font-size: 25px;
          font-weight: 700;
          margin-bottom: 15px;
          @include breakpoint(below, large) {
            width: 20px;
          }
        }

        .steps-desc__body {
          display: block;
          @include breakpoint(below, large) {
            width: calc(100% - 20px);
            margin-left: 25px;
          }

          p {
            color: $dark-grey;
          }
        }
      }
    }

    @include breakpoint(below, large) {

      flex-direction: column;

      .steps__step {
        flex: none;
        width: 100%;
        margin-top: 50px;

        img {
          display: inline-block;
          vertical-align: middle;
        }

        .steps-desc {
          display: inline-block;
          font-size: 19px;
          padding-left: 35px;

          width: 68%;
          .steps-desc__number {
            font-size: 25px !important;
          }

          .steps-desc__body {
            p {
              font-size: 19px;
            }
          }

        }
      }
    }

    @include breakpoint(below, small) {
      text-align: center;
      .steps__step {
        .steps-desc {
          padding: 0 20px;
          width: auto;
          .steps-desc__number {
            float: left;
            margin-top: -5px;
            margin-right: 5px;
          }
          .steps-desc__body {
            display: block;
          }
        }
      }

    }

  }
}

.mobile-menu-button {
  outline: none;
}

// visually hide element, but keep so screenreaders can still see
.visually-hidden {
  @include visually-hidden;
}

.rounded-corners {
  border-radius: 10px;
}

// display element as block
.block {
  display: block;
}

// classes that can be added to elements to give them repsonsive spacing
.padding-all {
  @include responsive-padding(all);
}

.padding-x {
  @include responsive-padding(x);
}

.padding-y {
  @include responsive-padding(y);
}

.padding-top {
  @include responsive-padding(top);
}

.padding-right {
  @include responsive-padding(right);
}

.padding-bottom {
  @include responsive-padding(bottom);
}

.padding-left {
  @include responsive-padding(left);
}

.padding-inside-block {
  @include responsive-padding(all, $scale: $spacing-inside-block);
}

.margin-all {
  @include responsive-margin(all);
}

.margin-x {
  @include responsive-margin(x);
}

.margin-y {
  @include responsive-margin(y);
}

.margin-top {
  @include responsive-margin(top);
}

.margin-right {
  @include responsive-margin(right);
}

.margin-bottom {
  @include responsive-margin(bottom);
}

.margin-left {
  @include responsive-margin(left);
}

// half size spacings
.padding-top--half {
  @include responsive-padding(top, $scale: $spacing-small);
}

.padding-bottom--half {
  @include responsive-padding(bottom, $scale: $spacing-small);
}

.padding-y--half {
  @include responsive-padding(y, $scale: $spacing-small);
}

.padding-x--half {
  @include responsive-padding(x, $multiplier: 0.5);
}

.margin-top--half {
  @include responsive-margin(top, $scale: $spacing-small);
}

.margin-bottom--half {
  @include responsive-margin(bottom, $scale: $spacing-small);
}

.margin-y--half {
  @include responsive-margin(y, $scale: $spacing-small);
}

.no-margin--top {
  margin-top: 0 !important;
}

.no-margin--right {
  margin-right: 0 !important;
}

.no-margin--bottom {
  margin-bottom: 0 !important;
}

.no-margin--left {
  margin-left: 0 !important;
}

strong {
  font-weight: bold;
}

.all-caps {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.sentence:first-letter {
  text-transform: capitalize
}
