.ad {
  .image-holder {
    margin-right: 20px;
    float: left;
    width: 102px;
    height: 102px;
    background-size: cover;
  }
  .content-holder {
    margin-left: 120px;
    h4 {
      text-transform: uppercase;
      margin: 0;
    }
    .small-text {
      font-size: 14px;
      line-height: 120%;
      max-width: 80%;
      margin: 10px 0;

      &.company {
        color: $blue;
        font-size: 19px;
      }

      .tag {
        white-space: nowrap;
        display: inline-block;
        margin-right: 5px;
        line-height: 18px;

        @include breakpoint(below, x-small) {
          white-space: normal;
        }

        &:after {
          content: "·";
          display: inline-block;
          margin-left: 5px;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
    a {
      font-size: 16px;
      color: $green;
    }
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
