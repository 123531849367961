.above-footer {
  display: flex;
  flex-direction: column;

  @include breakpoint(above, large) {
    flex-direction: row;

    > div {
      width: 50%;
    }
  }
}
