// this pulls up content when page is wider than 1000px, below it adds normal padding to the element

.pull-up-parent {
  @include responsive-margin(top, $multiplier: 2, $from: large);

  .pull-up {
    @include responsive-position(top, $multiplier: -2, $from: large); // pulls content up
    @include responsive-margin(bottom, $multiplier: -2, $from: large); // changing 'top' doesn't move element from orginal page flow position, so this pulls up content from below to take up the space
    @include responsive-padding(top, $to: large); // when it's not being pulled up
    position: relative;

    @include breakpoint(above, large) {
      padding-top: 0; // remove top padding now that content is being pulled up
    }
  }
}
