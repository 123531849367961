.image-text {
  position: relative;

  .image-text__text {
    @include responsive-padding(all);

    @include breakpoint(above, large) {
      width: 50%;
      margin-left: 50%;
    }
  }

  .image-text__image {
    background-image: url(../images/homepage/home-buying.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
    padding-top: 50%;
    overflow: hidden;

    @include breakpoint(above, large) {
      padding-top: 0;
      width: 50%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }
  }
}

// flip direction
.image-text--reversed {
  .image-text__text {
    @include breakpoint(above, large) {
      margin-left: 0;
    }
  }

  .image-text__image {
    background-image: url(../images/homepage/home-refi.jpg);

    @include breakpoint(above, large) {
      left: 50%;
    }
  }
}
