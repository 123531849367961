.site-header {
  align-items: center;
  border-top: 5px solid $green;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  position: relative;
  z-index: 3;

  @include breakpoint(above, large) {
    border-width: 10px;
    padding: 15px 40px;
  }

  .logo {
    a {
      display: block;

      img {
        height: auto;
        width: 155px;

        @include breakpoint(above, large) {
          width: 186px;
        }
      }
    }
  }
}

// mobile menu button
.mobile-menu-button {
  background: none;
  border: none;
  position: relative;
  height: 23px;
  width: 33px;

  &:hover {
    .mobile-menu-button__bar {
      background: $green;
    }
  }

  @include breakpoint(above, large) {
    display: none;
  }

  .mobile-menu-button__bar {
    height: 2px;
    background: $blue;
    transition: transform 0.3s, top 0.3s, background 0.3s;
    transform-origin: center;
    position: absolute;
    width: 100%;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 8px;
      transform-origin: left;
    }

    &:nth-child(3) {
      top: 16px;
      transform-origin: right;
    }

    &:nth-child(4) {
      top: 100%;
    }
  }

  &.open {
    justify-content: center;

    .mobile-menu-button__bar {
      background: $green;
    }

    .mobile-menu-button__bar:first-child {
      transform: rotate(-45deg);
      top: 50%;
    }

    .mobile-menu-button__bar:last-child {
      transform: rotate(45deg);
      top: 50%;
    }

    .mobile-menu-button__bar:nth-child(2) {
      transform: scaleX(0);
      transform-origin: right;
    }
    .mobile-menu-button__bar:nth-child(3) {
      transform: scaleX(0);
      transform-origin: left;
    }
  }
}
