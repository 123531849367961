#agent-reviews {
  .reviews-holder {
    margin-top: 40px;
    .left-side, .right-side {
      float: left;
      box-sizing: border-box;
    }
    .left-side {
      width: 33%;
      .list {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          margin-bottom: 25px;

          .stars {
            display: inline-block;

            .star {
              display: inline-block;
            }
          }

          p {
            margin-left: 10px;
            display: inline-block;
            color: $dark-grey;
            font-size: 14px;
            font-style: italic;
          }
          @include breakpoint(below, small) {
            margin-bottom: 0;
            &:first-child {
              border-top: 1px solid $light-grey;
              padding-top: 15px;
            }
          }
        }
      }
    }

    .right-side {
      width: 66%;

      .testimonial-holder {
        .testimonial {
          border-bottom: 1px solid $grey;
          padding: 50px 0;
          flex: none;
          display: block;

          .stars {
            display: block;
            flex: none;
            .star {
              display: inline-block;
            }
          }

          .comment {
            margin: 30px 0;
            line-height: 30px;
            font-style: italic;
            width: 100%;
            display: block;

            &.reply {
              background: $light-blue;
              padding: 15px;
              position: relative;
              top: 10px;
              display: inline-block;
              border-radius: 10px;

              @include breakpoint(below, small) {
                width: 100%;
                font-size: 15px;
                position: relative;
                top: -6px;
              }

              .testimonial__image {
                text-align: center;
                width: 55px;
                height: 55px;
                background-size: cover;
                display: inline-block;
                float: left;
              }

              p {
                margin-left: 75px;
              }

            }
          }
          .user {
            text-transform: uppercase;
            font-family: 'Open Sans Condensed', sans-serif;
            strong {
              font-weight: bold;
            }
          }
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    &:after {
      content: '';
      display: table;
      clear: both;
    }

    @include breakpoint(below, small) {
      display: table;

      .left-side, .right-side {
        width: 100%;
        float: none;
      }

      .right-side {
        display: table-header-group !important;

        .testimonial-holder {
          .testimonial {
            padding: 10px 0;
          }
        }
      }
    }
  }
}

.leave-review {
  h2 {
    .button {
      float: right;
    }
  }

  @include breakpoint(below, small) {
    h2 {
      .button {
        float: none;
        margin-top: 10px;
      }
    }
  }
}

.rating-banner {
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  padding: 20px 0;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .officer {
    .stars {
      font-size: 30px;
    }
  }

  .desc {
    margin-top: 10px;
  }
}
