html {
  min-width: 320px;
  background: $green;
  box-sizing: border-box;

  body {
    background: white;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

header,
main,
footer {
  background: $white;
  position: relative;
  z-index: 1;
}

img {
  width: auto;
  max-width: 100%;

  &.full-width {
    width: 100%;
  }
}

#profile-header-banner {
  color: $white;
  text-align: center;
  background-color: $blue;
  padding: 10px 0px;
}
