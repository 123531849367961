.site-footer {
  @include responsive-padding(all);

  background: $blue;
  color: $white;
  text-align: center;
  font-size: 16px;
  line-height: 1.6em;

  p + p, h6 + p {
    margin-top: 1em;
  }

  a,
  a:link,
  a:visited {
    color: $white;

    &:hover,
    &:focus {
      color: $white;
      text-decoration: underline;
    }
  }

  .site-footer__title {
    font-family: $open-sans-condensed;
    font-size: 20px;
    font-weight: 700;
  }

  @include breakpoint(above, x-small) {
    text-align: left;
  }

  .social {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;

    @include breakpoint(above, x-small) {
      justify-content: flex-start;
    }

    li {
      + li {
        margin-left: 10px;
      }

      a,
      a:link,
      a:visited {
        display: block;
        text-decoration: none;
        border: 1px solid transparent;
        transition: border 0.3s;
        text-align: center;
        width: 32px;
        line-height: 30px;
        border-radius: 50%;

        &:hover,
        &:focus {
          text-decoration: none;
          border: 1px solid $white;
        }

        span {
          display: block;
          font-size: 19px;
          line-height: 30px;
        }
      }
    }
  }
}

// terms of service/privacy policy link and site by link
.site-footer_bottom {
  @include responsive-margin(top, $scale: $spacing-small);

  display: flex;
  flex-direction: column;

  @include breakpoint(above, x-small) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .tos {
    display: flex;
    flex-direction: column;

    @include breakpoint(above, x-small) {
      align-items: center;
      flex-direction: row;
    }

    a + a {
      @include breakpoint(above, x-small) {
        margin-left: 15px;
      }

      @include breakpoint(above, small) {
        margin-left: 30px;
      }
    }
  }

  .site-by {
    @include breakpoint(above, x-small) {
      text-align: right;
    }
  }
}
