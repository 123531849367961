// text inputs
input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
  background: $off-white;
  border-radius: 10px;
  border: 1px solid $light-grey;
  color: $dark-grey;
  font-size: 16px;
  font-family: $open-sans;
  line-height: 23px;
  margin-bottom: 20px;
  max-height: 45px;
  min-height: 45px;
  padding: 10px 20px;
  width: 100%;

  @include breakpoint(above, small) {
    padding: 15px 25px;
    max-height: 55px;
    min-height: 55px;
  }

  &:focus {
    border-color: $green;
    outline: none;
  }

  &.has-error {
    border-color: $red;
  }
}

// ios selector that adds own style
// remove their appearance
input[type=text] {
  -webkit-appearance: none;

  &.has-error {
    border-color: $red;

    //&::-webkit-input-placeholder {
    //    color: $red;
    //}
  }
}

/* Removing arrows from input[type=number] Source: W3Schools */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.i-agree-wrapper {
  width: 260px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 20px;

  label {
    cursor: pointer;
    position: relative;

    .checkbox {
      display: inline-block;
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid $black;
      border-radius: 4px;
      top: 3px;
      left: -25px;

      &:after {
        content: '';
        width: 9px;
        height: 5px;
        position: absolute;
        top: 6px;
        left: 5px;
        border: 3px solid $black;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        transform: rotate(-45deg);
      }
      &:hover::after {
        opacity: 0.5;
      }
    }

    .radio-button {
      display: inline;
      position: absolute;
      width: 16px;
      height: 16px;
      border: 1px solid $black;
      border-radius: 15px;
      top: 3px;
      left: -20px;

      &:after {
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        top: 2px;
        left: 2px;
        border: 5px solid #000;
        background: transparent;
        opacity: 0;
        border-radius: 5px;
      }
      &:hover::after {
        opacity: 0.5;
      }
    }

  }
  input[type=checkbox] {
    visibility: hidden;
    &:checked + label {
      .checkbox {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  input[type=radio] {
    display: none;
    &:checked + label {
      .radio-button {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.radio-labels {
  margin-left: 20px;
}

// set placeholder colors
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $medium-grey;
  &.has-error {
    color: $red;
  }
}

::-moz-placeholder { /* Firefox 19+ */
  color: $medium-grey;
}

:-ms-input-placeholder { /* IE 10+ */
  color: $medium-grey;
}

:-moz-placeholder { /* Firefox 18- */
  color: $medium-grey;
}

// special styles for search for loan officers form
.loan-officer-form {
  // buying/refi are radio buttons
  // hide inputs and style labels

  input:not([type="radio"]) {
    background: rgba($white, 0.7);
    font-family: $open-sans-condensed;
    font-weight: 700;
    font-size: 18px;
  }

  .form-item--radio {
    align-items: center;
    display: flex;
    margin-bottom: 20px;

    .form-radio {
      // hide inputs
      input {
        @include visually-hidden;
      }

      label {
        border-radius: 10px;
        border: 2px solid transparent;
        color: $white;
        cursor: pointer;
        display: block;
        font-family: $open-sans-condensed;
        font-weight: 700;
        letter-spacing: 0.05em;
        margin-right: 10px;
        padding: 7px 18px;
        position: relative;
        text-transform: uppercase;
        transition: background 0.3s, border 0.3s;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%) translateY(100%);
          bottom: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $white;
          opacity: 0;
          transition: opacity 0.3s;
        }

        &:hover {
          background: rgba($white, 0.1);
        }
      }

      input:checked + label {
        border-color: $white;

        &::after {
          opacity: 1;
        }
      }
    }
  }
}

// with this class, the search button will be inline with one text field
.inline-search-button {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .form-item--text {
    flex: 1 0 auto;

    input {
      margin-bottom: 0;
      margin-top: 0;
      margin-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  // search button loses text below 600
  button.button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;

    @include breakpoint(below, small) {
      text-indent: -999px;

      &::after {
        text-indent: 0;
        margin-left: 0;
      }
    }
  }

  .form-item--radio {
    width: 100%;
  }
}

// used when a field is incorrect - add to div in which the error shows
// should appear below input
.form-error {
  color: $red;
  font-size: 13px;

  .error-message-wrapper {
    position: relative;

    .error-message {
      position: absolute;
      top: -20px;
    }
  }
}

// To show hints with fields
.help-text-wrapper {
  color: $medium-grey;
  font-size: 13px;
  position: relative;

  .help-text {
    position: absolute;
    top: -20px;
  }
}

// when form is on green background submit buttons has white outline
.form-on-green {
  button.button {
    border: 2px solid transparent;
    transition: border 0.3s, background .3s;
    line-height: 21px; // reduce line height so added border doesn't make it taller

    &:hover,
    &:focus {
      border-color: $white;
    }
  }
}

// custom spacing below 1000 for this one only
.loan-officer-form--in-hero {
  @include breakpoint(below, large) {
    margin-top: 40px !important;
  }
}

textarea {
  resize: vertical;
  min-height: 100px;
  max-height: none;
}

form.flex-form {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .form-item {
    width: 100%;

    &.half-width {
      width: calc(50% - 20px);

      @include breakpoint(below, small) {
        width: 100%;
      }
    }
  }
}

.form-status-msg-container {
  border-radius: 9px;
  color: $white;
  font-size: 18px;
  margin-bottom: 20px;
  padding: 15px;

  &.success {
    background: $green;
  }

  &.error {
    background: darken($red, 20%);
  }
}

.search-wrapper {
  position: relative;

  .dropdown-wrapper {
    position: absolute;
    background: white;
    max-height: 270px;
    top: 55px;
    overflow-y: auto;
    width: 100%;
    z-index: 1;

    .search-headings {
      font-size: 14px;
      padding: 5px 10px;
      color: $grey;
      text-align: left;
      cursor: default;
    }

    .search-option {
      color: $black;
      cursor: pointer;
      font-size: 16px;
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid $light-grey;
      transition: .35S;

      &:hover {
        background: $light-green;
        color: white;
      }
    }
  }
}

.close-icon {
  background-color: $medium-grey;
  border: none;
  border-radius: 50%;
  color: $white;
  display: block;
  font-size: 20px;
  font-weight: bolder;
  height: 28px;
  padding: 0px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 13px;
  width: 28px;
  z-index: 1;

  @include breakpoint(below, small) {
    top: 10px;
  }
}

#footer-search {
  .using-top {
    display: none !important;
  }
}

.hero {
  .using-bottom {
    display: none !important;
  }
}

#buying {
  .using-bottom {
    display: none !important;
  }
}

#refinance {
  .using-bottom {
    display: none !important;
  }
}

.select-wrapper {
  position: relative;

  .select-value {
    -webkit-appearance: none;
    background: $off-white;
    border: 1px solid $light-grey;
    border-radius: 10px;
    color: $dark-grey;
    cursor: pointer;
    font-family: $open-sans;
    font-size: 16px;
    line-height: 23px;
    padding: 15px 25px;
    max-height: 55px;
    min-height: 55px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &[data-value="Select your option"], &.placeholder {
      color: $medium-grey;
    }

    &::after {
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $light-grey;
      clear: both;
      content: '';
      height: 0;
      position: absolute;
      right: 10px;
      top: 23px;
      width: 0;
      transform: rotate(0deg);
      transition: transform 150ms ease;
    }

    &.open {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        transform: rotate(-180deg) translate(0px, 10px);
      }
    }
  }

  .select-options {
    -webkit-appearance: none;
    background: $off-white;
    border: 1px solid $light-grey;
    border-top: none;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: $dark-grey;
    cursor: pointer;
    font-family: $open-sans;
    font-size: 16px;
    line-height: 23px;
    position: absolute;
    padding: 0px 25px;
    top: 45px;
    width: 100%;
    z-index: 1;

    .option {
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        font-weight: bold;
        transition: 0.5s all;;
      }
    }
  }
}
