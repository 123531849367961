@mixin columns-wrapper($split-dimension, $negative-margin: $spacing-columns) {
  @include responsive-margin(left, -1, $scale: $negative-margin, $from: $split-dimension);

  display: flex;
  flex-wrap: wrap;


  &.middle-align {
    align-items: center;
  }

  &.bottom-align {
    align-items: flex-end;
  }
}

@mixin columns($column-count, $column-scale: $spacing-columns) {
  @include responsive-margin(top, $scale: $column-scale);

  width: 100%;

  // first child will never need row spacing.
  // spacing from last child should come from container
  &:first-child {
    margin-top: 0;
  }

  // ////////////////////
  // 2 columns
  // ////////////////////
  @if ($column-count == 2) {
    @include responsive-margin(left, $scale: $column-scale, $from: $two-column-split);
    $column-width-map: (
      $two-column-split: 50%
    );
    @include responsive-column-spacing($two-column-split, $column-scale, $column-width-map);

    @include breakpoint(above, $two-column-split) {
      // last two children don't need bottom margin now
      &:nth-child(-n+2) {
        margin-top: 0;
      }
    }
  } //end column-count 2

  @if ($column-count == 'special-2') {
    @include responsive-margin(left, $scale: $column-scale, $from: $two-column-split);
    $column-width-map-left: (
      $two-column-split: 75%
    );
    $column-width-map-right: (
      $two-column-split: 25%
    );

    &:nth-child(odd) {
      @include responsive-column-spacing($two-column-split, $column-scale, $column-width-map-left);
    }

    &:nth-child(even) {
      @include responsive-column-spacing($two-column-split, $column-scale, $column-width-map-right);
    }
    @include breakpoint(above, $two-column-split) {
      // last two children don't need bottom margin now
      &:nth-child(-n+2) {
        margin-top: 0;
      }
    }
  } //end column-count 2

  // ////////////////////
  // 3 columns
  // ////////////////////
  @if ($column-count == 3) {
    $column-width-map: (
      $three-column-split: 33.33%
    );

    @include responsive-margin(left, $scale: $column-scale, $from: $three-column-split);
    @include responsive-column-spacing($three-column-split, $column-scale, $column-width-map);

    @include breakpoint(above, $three-column-split) {
      // last three children don't need bottom margin now
      &:nth-child(-n+3) {
        margin-top: 0;
      }
    }
  } //end column-count 3

  // ////////////////////
  // 4 columns
  // ////////////////////
  @if ($column-count == 4) {
    // @include responsive-padding(left, $scale: $column-scale, $from: $four-column-split-one);

    $column-width-map: (
      $four-column-split-one: 50%,
      $four-column-split-two: 25%
    );
    @include responsive-margin(left, $scale: $column-scale, $from: $four-column-split-one);
    @include responsive-column-spacing($four-column-split-one, $column-scale, $column-width-map);

    @include breakpoint(above, $four-column-split-one) {
      // width: 50%;

      // last three children don't need bottom margin now
      &:nth-child(-n+2) {
        margin-top: 0;
      }
    }

    @include breakpoint(above, $four-column-split-two) {
      // width: 25%;

      &:nth-child(-n+4) {
        // last four children don't need bottom margin now
        margin-top: 0;
      }
    }
  } //end column-count 4


  // ////////////////////
  // 4 columns -- thin
  // ////////////////////
  @if ($column-count == '4-thin') {
    // @include responsive-padding(left, $scale: $column-scale, $from: $four-column-split-one);

    $column-width-map: (
      'x-small': 50%,
      'medium': 25%
    );
    @include responsive-margin(left, $scale: $column-scale, $from: 'x-small');
    @include responsive-column-spacing('x-small', $column-scale, $column-width-map);

    &:nth-child(-n+2) {
      margin-top: 0;
    }

    @include breakpoint(above, medium) {
      // width: 25%;

      &:nth-child(-n+4) {
        // last four children don't need bottom margin now
        margin-top: 0;
      }
    }
  } //end column-count 4


  // ////////////////////
  // 4 columns
  // ////////////////////
  @if ($column-count == 5) {
    // @include responsive-padding(left, $scale: $column-scale, $from: $four-column-split-one);

    $column-width-map: (
      'x-small': 50%,
      'large': 33.33%
    );
    @include responsive-margin(left, $scale: $column-scale, $from: 'x-small');
    @include responsive-column-spacing('x-small', $column-scale, $column-width-map);

    @include breakpoint(above, $four-column-split-one) {
      &:nth-child(-n+2) {
        margin-top: 0;
      }
    }

    @include breakpoint(above, $four-column-split-two) {
      &:nth-child(-n+4) {
        // last four children don't need bottom margin now
        margin-top: 0;
      }
    }
  } //end column-count 5
}

// mixin to put responsive spacing into a calc function for width
@mixin responsive-column-spacing($column-breakpoint, $scale-to-use, $width-map) {
  // get the breakpoint at which the columns break
  $intBreakpoint: map-get($breakpoints, $column-breakpoint);
  // var to keep track of column widths to use
  $previous-width: null;

  // map over all point in user defined scale
  @each $breakpoint, $value in $scale-to-use {
    $current-width: map-get($width-map, $breakpoint);
    $width-to-use: if($current-width, $current-width, $previous-width);
    // check if it's bigger than the initial breakpoint provided
    @if map-get($breakpoints, $breakpoint) >= $intBreakpoint {
      // if true, add a breakpoint for the width
      @include breakpoint('above', $breakpoint) {
        width: calc(#{$width-to-use} - #{nth($value, 2)});
      }
    }

    @if $current-width {
      $previous-width: $current-width;
    }
  } // end each
}

/// Test if map got all `$keys` at first level
/// @author Hugo Giraudel
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Keys to test
/// @return {Bool}
@function map-has-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
  }

  @return true;
}
