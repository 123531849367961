// ////
// main colors
// ////
$blue: #334B77;
$light-blue: #F2F5FB;

$dark-green: #317E3D;
$green: #4DA55B;
$light-green: #A0CCA7;

$red: #EE3840;

// ////
// black/greys/white
// ////

// listed in order from dark to light
$black: #000;
$dark-grey: #6A6A6A;
$medium-grey: #9A9A9A;
$grey: #BABABA;
$light-grey: #DADADA;
$off-white: #FAFAFA;
$white: #fff;

// alert colors
$alert-info-background: #daedf8;
$alert-info-color: #316f8e;
