.tooltip {
  position: relative;
  display: inline-block;

  .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: $medium-grey;
    color: #fff;
    text-align: center;
    padding: 6px 8px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 14px;
    position: absolute;
    z-index: 1;
    &::after {
      content: " ";
      position: absolute;
      border-width: 5px;
      border-style: solid;
    }
  }

  &.right, &.left {
    .tooltip-text {
      top: -6px;
      &::after {
        top: 50%;
        margin-top: -5px;
      }
    }
  }

  &.right {
    .tooltip-text {
      left: calc( 100% + 8px );
      &::after {
        right: 100%;
        border-color: transparent $medium-grey transparent transparent;
      }
    }
  }

  &.left {
    .tooltip-text {
      right: calc( 100% + 8px );
      &::after {
        left: 100%;
        border-color: transparent transparent transparent $medium-grey;
      }
    }
  }

  &.top, &.bottom {
    .tooltip-text {
      left: 50%;
      margin-left: -60px;
      &::after {
        left: 50%;
        margin-left: -5px;
      }
    }
  }

  &.top {
    .tooltip-text {
      bottom: calc( 100% + 8px );
      &::after {
        top: 100%;
        border-color: $medium-grey transparent transparent transparent;
      }
    }
  }

  &.bottom {
    .tooltip-text {
      top: calc( 100% + 8px );
      &::after {
        bottom: 100%;
        border-color: transparent transparent $medium-grey transparent;
      }
    }
  }

  &:hover .tooltip-text {
    visibility: visible;
  }
}
