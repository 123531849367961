.process {
  @include responsive-padding(x);
  @include angle(before, $flip: true, $angle: 5deg);
  @include angle(after, $angle: 5deg);

  margin: 4% 0;
}

.steps {
  display: flex;
  flex-direction: row;

  img {
    border-radius: 10px;
    width: auto;
  }

  @include breakpoint(above, large) {
    flex-direction: row;
    margin-left: -40px;

  }

  .steps__step {
    //@include responsive-margin(top, $to: large);

    //text-align: center;
    position: relative;

    &:first-child {
      margin-top: 0;
    }

    @include breakpoint(below, small) {
      width: calc(20% - 10px);
      flex-direction: column;
      margin-top: 0;
      margin-left: 5px;
      margin-right: 5px;

    }

    @include breakpoint(above, large) {
      width: calc(20% - 40px);
      margin-left: 40px;
      flex-direction: column;
      margin-top: 0;
    }

    @include breakpoint(below, large) {
      width: calc(20% - 0px);
      flex-direction: column;
      margin-top: 0;
    }

    .step-number {
      width: 64px;
      height: 64px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
      background: $light-blue;
      border: 1px solid $grey;
      display: inline-block;
      font-family: 'Open Sans Condensed', sans-serif;
      font-size: 30px;
      line-height: 60px;
      text-align: center;
      font-weight: bold;
      color: $grey;
      box-sizing: border-box;
      margin: 0 auto 18px;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: $grey;
        left: 60%;
        top: 23%;
        position: absolute;
        z-index: -1;
      }

    }

    .steps-desc {
      display: flex;
      text-align: center;
      flex-direction: column;

      @include breakpoint(below, large) {
        flex-direction: column;

        .step-number {
          width: 48px;
          height: 48px;
          line-height: 44px;
          &:after {
            top: 20%;
          }
        }
        .steps-desc__number {
          font-size: 15px !important;
          line-height: normal;
        }
        .steps-desc__body {
          font-size: 10px;
          line-height: normal;
          padding-left: 0 !important;
        }
      }

      @include breakpoint(below, small) {

        .step-number {
          font-size: 20px;
          width: 33px;
          height: 33px;
          line-height: 30px;
          background-size: 50% !important;
          &:after {
            top: 16px;
          }
        }
      }

      @include breakpoint(above, large) {
        padding-left: 0;
      }

      .steps-desc__number {
        color: $grey;
        font-family: $open-sans-condensed;
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 15px;
      }

      .steps-desc__body {
        padding-left: 10px;
        color: $grey;

        @include breakpoint(above, small) {
          padding-left: 0;

        }
      }
    }
    &.active {
      .step-number {
        background: $green;
        color: white;
        border: transparent;
      }
      .steps-desc__number {
        color: $green;
      }
    }
    &.completed {
      .step-number {
        background: url("../images/tick.svg") no-repeat center $light-green;
        color: white;
        border: transparent;
        font-size: 0;
      }
      .steps-desc__number {
        color: $green;
      }
    }
    &:last-child {
      .step-number {
        &:after {
          display: none;
        }
      }
    }
  }
}

.img-spacer {
  margin-top: -10%;
  margin-bottom: -32%;

  @include breakpoint(above, large) {
    margin-top: -18%;
  }
}
