@mixin button {
  align-items: center;
  background: $green;
  border: none;
  border-radius: 10px;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  font-family: $open-sans-condensed;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 25px;
  outline: 0;
  overflow: hidden;
  padding: 10px 20px;
  position: relative;
  text-decoration: none;
  transition: background 0.3s;
  text-transform: uppercase;

  &::after {
    @include icomoon;

    content: '\e904';
    display: inline-block;
    margin-left: 20px;
    transition: transform 0.3s;

    @include breakpoint(above, small) {
      margin-left: 25px;
    }

  }
  &:disabled {
    background: $grey;
    cursor: default;
    color: rgba(255, 255, 255, .5);
    &:hover {
      background: $grey;
    }
  }

  @include breakpoint(above, small) {
    font-size: 20px;
    padding: 15px 25px;
  }

  &:hover,
  &:focus {
    background: $dark-green;

    &::after {
      transform: translateX(5px);
    }
  }
  &.plain {
    &:after {
      display: none;
    }
  }

  &.btn-image {
    max-height: 350px;
    max-width: 350px;
    min-width: auto;
    width: 19vw;
    height: 19vw;
    .image-holder {
      width: 100%;
      .icon {
        border: 4px solid white;
        width: 130px;
        height: 130px;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        margin: auto;
        box-sizing: border-box;
        margin-bottom: 10px;
        img {
          transform: translateY(50%);
        }
      }

    }

    span {
      width: 100%;
      display: block;
    }

    &:after {
      display: none;
    }

    @include breakpoint(below, large) {
      width: 24vw;
      height: 24vw;

      .image-holder {
        .icon {
          width: 90px;
          height: 90px;
          margin-bottom: 10px;
          border-width: 3px;

          img {
            width: 65%;
            height: auto;
          }
        }
      }

      .label {
        font-size: 16px;
        letter-spacing: 0px;
      }

    }

    @include breakpoint(below, small) {
      width: 100%;
      height: auto;
      max-width: none;

      .image-holder {
        width: auto;
        display: inline-block;
        vertical-align: middle;

        .icon {
          width: 50px !important;
          height: auto !important;
          border: none !important;
          margin-bottom: 0 !important;
          margin-right: 10px;

          img {
            transform: none;
          }
        }
      }

      .label {
        display: inline-block;
        width: auto;
        vertical-align: middle;
      }
    }
  }
}
