.map {
  position: relative;
  width: 100%;
  height: 430px;

  &.embed {
    height: 100vh;
  }

  #map-legend {
    background: #fff;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
    padding: 5px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;

    div {
      display: inline-block;
      font-weight: 100;

      span {
        border-radius: 50%;
        display: block;
        float: left;
        height: 20px;
        width: 20px;
        margin-top: 5px;

        &.refinance {
          background: #6991fd;
        }

        &.buying {
          margin-left: 5px;
          background: #fd7567;
        }
      }

      label {
        font-size: 12px;
        margin-left: 5px;
        margin-bottom: 10px;
      }
    }
  }
  #copy-embeded-code {
    background: #fff;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
    padding: 5px;
    position: absolute;
    right: 80px;
    font-size: 1.3em;
    top: 10px;
    z-index: 10;
    cursor: pointer;
  }

  .map-marker {
    position: absolute;
    transform: translate(-50%, -100%);
    top: 2px;
  }
  .info-window {
    box-shadow: 4px 4px 12px 4px #787878;
    z-index: 101;
    position: absolute;
    border-radius: 12px;
    max-height: 200px;
    width: 300px;
    background-color: #fff;
    color: #000;
    padding: 12px 0;
    overflow-y: scroll;
    h2 {
      font-size: 22px;
      margin-bottom: .5em;
      padding: 8px 16px;
    }
    .icon {
      position: absolute;
      right: 8px;
      padding: 0;
    }
    span {
      font-size: 14px;
      padding: 0 16px;
    }
    .transaction-item {
      margin-top: 12px;
      padding: 0 16px;
      padding-bottom: 8px;
      p {
        font-size: 15px;
        margin: 4px 0;
        b {
          font-weight: 500;
        }
        &.comments {
          font-style: italic;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid rgb(216, 216, 216);
      }
    }
  }
}

