@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?2un4qm');
  src: url('../fonts/icomoon.eot?2un4qm#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.woff2?2un4qm') format('woff2'),
  url('../fonts/icomoon.ttf?2un4qm') format('truetype'),
  url('../fonts/icomoon.woff?2un4qm') format('woff'),
  url('../fonts/icomoon.svg?2un4qm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star:before {
  content: "\e908";
}

.icon-envelope:before {
  content: "\e909";
}

.icon-arrow-right:before {
  content: "\e904";
}

.icon-arrow-left:before {
  content: "\e905";
}

.icon-arrow-up:before {
  content: "\e906";
}

.icon-arrow-down:before {
  content: "\e907";
}

.icon-check:before {
  content: "\e903";
}

.icon-twitter:before {
  content: "\e90a";
}

.icon-brand:before {
  content: "\e90a";
}

.icon-tweet:before {
  content: "\e90a";
}

.icon-social:before {
  content: "\e90a";
}

.icon-instagram:before {
  content: "\e900";
}

.icon-brand2:before {
  content: "\e900";
}

.icon-social2:before {
  content: "\e900";
}

.icon-facebook:before {
  content: "\e901";
}

.icon-facebook-f:before {
  content: "\e901";
}

.icon-linkedin:before {
  content: "\e902";
}

.icon-google-plus {
  fill: $white;
  width: 80%;
}

.icon-youtube {
  width: 70%;
  position: absolute;
  top: 3px;
  left: 4px;
}

.icon {
  cursor: pointer;
}
