#footer-search {
  form {
    .form-item--submit {
      flex: none;
      display: block;
      width: 100%;
      margin-top: 20px;
      .button {
        -webkit-border-radius: 10px !important;
        -moz-border-radius: 10px !important;
        border-radius: 10px !important;
        border: 2px solid transparent;
        transition: border .3s, background .3s;
        box-sizing: border-box;
        &:hover {
          border-color: white;
        }
      }
    }
    .form-item--text {
      .geolocate {
        -webkit-border-radius: 10px !important;
        -moz-border-radius: 10px !important;
        border-radius: 10px !important;
      }
    }
  }
}
