$hover-body-background: $dark-grey;

.hover-pop-up {
  cursor: pointer;
  position: relative;

  .hover-pop-up__icon {
    font-size: 15px;
  }

  .hover-pop-up__body {
    background: $hover-body-background;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    color: $white;
    cursor: auto;
    left: auto;
    font-size: 14px;
    margin-right: 10px; //moves right edge of box to center of image
    opacity: 0;
    padding: 20px;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: left;
    top: 0;
    transform: translateY(-100%);
    transition: transform .33s, opacity .33s, background .33s;
    width: 250px;

    @include breakpoint(above, x-large) {
      width: 320px;
      padding: 20px 25px;
    }

    &::after {
      content: '';
      display: block;
      border-bottom: 15px solid transparent;
      border-left: 15px solid $hover-body-background;
      border-top: 15px solid transparent;
      height: 0;
      left: auto;
      position: absolute;
      right: 0;
      top: 100%;
      transform-origin: right;
      transform: translateY(-50%) rotate(-45deg);
      width: 0;
    }

    // empty space so the hover will carry over from image to hover body
    // when user moves slight to right or bottom of box
    &::before {
      content: '';
      display: block;
      height: calc(100% + 30px);
      left: 15px;
      position: absolute;
      bottom: -30px;
      width: 100%;
      z-index: -1;
    }

    &.hover-pop-up__body--left-align {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 0;
      left: 0;
      right: auto;
      margin-left: 10px;
      margin-right: 0;

      &::after {
        left: 0;
        transform-origin: left;
        transform: translateY(-50%) rotate(45deg);
        border-left: none;
        border-right: 15px solid $hover-body-background;
      }

      &::before {
        left: -15px;
      }
    }
  }
}

.hover-pop-up:hover,
.hover-pop-up:focus {
  .hover-pop-up__body {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(calc(-100% - 30px));
    z-index: 3;
  }
}
