// Two identical keyframes with different names to allow the animation to run each time the class toggles.
@keyframes profile-info-open-animation {
    0%{
        opacity: 0;
    }
    33%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes profile-info-min-animation {
    0%{
        opacity: 0;
    }
    33%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

$container-height: 290px;
$container-height-minimized: 124px;
$container-padding: 16px;
$picture-size: 108px;
$picture-size-minimized: 48px;
$animation-timing-function: cubic-bezier(0.18, 0.89, 0.5, 1.1);

.popup-container {
    position: fixed;
    width: 300px;
    right: 16px;
    bottom: 16px;
    z-index: 100;
    padding: $container-padding;
    border-radius: 12px;
    background-color: white;
    box-shadow: 2px 2px 6px 4px rgba($color: #4DA55B, $alpha: 0.3);

    height: $container-height-minimized;

    transition-property: height, width, padding;
    transition-duration: 500ms;
    transition-timing-function: $animation-timing-function;

    & * {
        line-height: 1em; // Reset line-height because each element's height needs to be fixed according to its font-size
        transition-duration: 500ms;
        transition-timing-function: $animation-timing-function;
    }

    .profile {
        width: 100%;
        display: block;
        position: relative;
        transform: translateY(-#{$picture-size-minimized + 16px});
        transition-property: transform;

        .picture-container {
            position: relative;
            display: block;
            transform: translate(0, #{$picture-size-minimized + 16px});
            width: $picture-size-minimized;
            margin: 0 12px 16px 0;
            left: 0;

            transition-property: margin, width, left, transform;

            .picture {
                width: $picture-size-minimized;
                height: $picture-size-minimized;
                border-radius: 5px;
                object-fit: cover;

                transition-property: height, width, border-radius;
            }
            .rating {
                position: absolute;
                bottom: 0;
                width: $picture-size-minimized;
                padding: 1px 2px;
                border-radius: 0 0 8px 8px;
                display: flex;
                justify-content: center;
                background-color: #fffaef;

                opacity: 0;

                transition-property: opacity, width;

                .stars {
                    margin: 0;
                    font-size: 10px;

                    transition-property: font-size;
                }
            }
        }
        .profile-info {
            display: block;
            position: relative;
            width: calc(100% - #{$picture-size-minimized + 12px});
            left: $picture-size-minimized + 12px;
            margin: 0 0 16px 0;

            transition-property: width, left, margin;
            animation-name: profile-info-min-animation;
            animation-duration: 500ms;
            animation-fill-mode: both;
            animation-timing-function: $animation-timing-function;

            .name, .job-title, .nmls {
                text-align: left;
            }

            .name {
                font-size: 18px;
                margin: 0 0 5px 0;
                font-weight: 600;
            }

            .job-title {
                font-size: 13px;
                margin: 5px 0;
            }

            .nmls {
                font-size: 13px;
                margin: 3px 0;
                display: none;
            }
        }
    }

    .button {
        position: absolute;
        bottom: 16px;
        width: calc(100% - #{$container-padding * 2});
        font-size: 16px;
        padding: 6px 12px;
        justify-content: center;

        transition-property: padding, font-size;

        &::after {
            content: "";
            display: none;
        }
    }

    .popup-expand-icon, .popup-close-icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e1f5e4;
        border: 1px solid #5abe6b;
        border-radius: 50%;

        position: absolute;
        top: -10px;
        font-size: 12px;
        cursor: pointer;
    }

    .popup-close-icon {
        right: -10px;
    }

    .popup-expand-icon {
        right: 20px;
    }

    &.open {
        height: $container-height;

        .profile {
            transform: translateY(0);

            .picture-container {
                width: $picture-size;
                left: 50%;
                $half-picture-size: calc($picture-size / 2);
                transform: translate(-$half-picture-size, 0px);
                margin: 0 0 12px 0;
                .picture {
                    width: $picture-size;
                    height: $picture-size;
                    border-radius: 8px;
                }
                .rating {
                    opacity: 1;
                    width: $picture-size;
                    .stars{
                        font-size: 14px;
                    }
                }
            }

            .profile-info {
                width: 100%;
                margin: 0 0 16px 0;
                left: 0;
                animation-name: profile-info-open-animation;

                .name, .job-title, .nmls {
                    text-align: center;
                }

                .nmls {
                    display: block;
                }
            }
        }

        .button {
            font-size: 20px;
            padding: 12px 24px;
        }
    }

    &.closed {
        width: 0;
        height: 0;
        padding: 0;

        animation: popup-closed-animation 200ms both $animation-timing-function;
        * {
            visibility: none;
            pointer-events: none;
        }
    }
}

@keyframes popup-closed-animation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}
