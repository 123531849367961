#tos, #privacy {
  line-height: 1.5em;
  text-align: justify;

  h4 {
    margin-top: 20px;
  }
  .heading {
    color: $blue;
  }
  ul {
    padding-left: 50px;
  }
  .point-list {
    list-style-type: disc;
  }
}
