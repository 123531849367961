// vertical line class goes on parent
// span with class of line needs to be added as child

.vertical-line {
  position: relative;

  span.line {
    @include responsive-position(left);

    background: $green;
    bottom: 0;
    content: '';
    display: block;
    height: 80px;
    position: absolute;
    transform: translateY(20px);
    width: 3px;

    @include breakpoint(above, medium) {
      height: 140px;
      transform: translateY(50px);
    }

    @include breakpoint(above, large) {
      height: 170px;
      transform: translateY(40%);
    }

    @include breakpoint(above, x-large) {
      transform: translateY(50%);
    }

    @include breakpoint(above, xx-large) {
      height: 435px;
    }

    // when the line is in content, the angles is handled differently
    // than the hero, so the line needs to be positioned differently
    // and hidden below 1000
    &.line--in-content {
      @include breakpoint(below, large) {
        display: none !important
      }

      @include breakpoint(above, large) {
        transform: translateY(120%);
      }

      @include breakpoint(above, xx-large) {
        transform: translateY(110%);
      }
    }
  }
}
