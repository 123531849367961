.hero {
  @include responsive-padding(x);

  align-items: center;
  display: flex;
  height: 0;
  justify-content: center;
  padding-top: 41.66%;
  position: relative;
  max-height: 700px;
  min-height: 300px;

  .hero__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .hero__content {
    @include responsive-position(left);
    @include responsive-padding(right);

    max-width: 600px;
    position: absolute;
    top: 25%;
    z-index: 1;

    h1 {
      margin-bottom: 0px;
    }
  }

  form {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.hero--full-size {
  @include responsive-padding(all);

  clip-path: none;
  height: calc(100vh - 80px);
  max-height: 950px;
  min-height: 375px;
  padding-top: 0;
  text-align: center;

  &::after {
    display: none;
  }

  .hero__content {
    position: static;
    transform: none;
    //margin-left: auto;
    //margin-right: auto;
    max-width: 900px;
    left: auto;
    padding-right: 0;

    h1 {
      margin-bottom: 30px;
    }
  }

  @include breakpoint(above, large) {
    height: calc(100vh - 105px);
  }
}

// ////
// hero images
// ////
.hero--homepage {
  background-position: center;
  background-size: cover;
  background-image: url(../images/heroes/hero-sm.jpg);

  @include breakpoint(above, small) {
    background-image: url(../images/heroes/hero-md.jpg);
  }

  @include breakpoint(above, medium) {
    background-image: url(../images/heroes/hero-lg.jpg);
  }
}
