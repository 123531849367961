.testimonial {
  @include breakpoint(above, x-small) {
    display: flex;
  }

  .testimonial__image_wrapper {
    .testimonial__image {
      text-align: center;
      margin: 0 auto 25px auto;
      width: 130px;
      height: 130px;
      background-size: cover;

      @include breakpoint(above, x-small) {
        margin-bottom: 0;
        min-width: 110px;
        width: 110px;
      }

      @include breakpoint(above, small) {
        min-width: 130px;
        width: 130px;
      }

    }

  }

  .testimonial__body {
    color: $white;
    font-size: 20px;
    line-height: 1.65em;
    font-style: italic;

    @include breakpoint(above, x-small) {
      padding-left: 30px;
    }
  }

  .testifier {
    font-style: normal;
    font-family: $open-sans-condensed;
    text-transform: uppercase;

    .testifier__name {
      font-weight: 700;
    }

    .testifier__location {
      font-weight: 300;
    }
  }
}
