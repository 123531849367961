// size at which menu goes from mobile to dekstop layout
$menu-breakpoint: 'large';

.main-nav {
  background: $green;
  left: 0;
  padding: 0px;
  position: absolute;
  transition: padding 0.3s;
  top: 100%;
  width: 100%;
  z-index: 2;

  @include breakpoint(above, $menu-breakpoint) {
    background: none;
    padding: 0;
    position: static;
    width: auto;
  }

  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(above, $menu-breakpoint) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .main-nav__item {

    a {
      color: $white;
      display: block;
      font-family: $open-sans-condensed;
      font-size: 17px;
      font-weight: 700;
      max-height: 0;
      line-height: 50px;
      overflow: hidden;
      text-decoration: none;
      text-transform: uppercase;
      transition: color 0.3s, max-height 0.3s;

      &:hover,
      &:focus {
        color: $dark-green;

        @include breakpoint(above, $menu-breakpoint) {
          color: $green;
        }
      }

      @include breakpoint(above, $menu-breakpoint) {
        color: $blue;
        font-size: 18px;
        line-height: 1.2em;
        padding: 0 17px;
        max-height: none;
      }
    }
  }

  .main-nav__login {
    a {
      background: $green;

      &:hover,
      &:focus {
        @include breakpoint(above, $menu-breakpoint) {
          color: $white;
          background: $dark-green;
        }
      }

      @include breakpoint(above, $menu-breakpoint) {
        color: $white;
        line-height: 2.0em;
        border-radius: 5px;
      }
    }
  }
}

// main nav open at mobile
@include breakpoint(below, $menu-breakpoint) {
  .main-nav.open {
    padding: 15px 0px;

    ul {

    }

    .main-nav__login {
      a {
        max-height: 50px;
      }
    }

    .main-nav__item {
      a {
        max-height: 50px;
      }
    }
  }
}
