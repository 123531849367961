$loan-officer-column-spacing: (
    'xx-small': 30px 0,
    'x-small': 40px 25px,
    'small': 50px 30px,
    'medium': 60px 40px,
    'large': 70px 45px,
    'x-large': 90px 45px,
    'xx-large': 100px 45px,
    'xxx-large': 150px 45px,
);

$loan-officer-stack-breakpoint-start: small;
$loan-officer-stack-breakpoint-end: large;

.loan-officer-wrapper {
  justify-content: center;
  @include columns-wrapper($three-column-split, $loan-officer-column-spacing);
}

.loan-officer {
  @include columns(3, $loan-officer-column-spacing);

  align-items: center;
  display: flex;
  font-size: 15px;

  // never have images go beyond their natural width
  img {
    width: auto;
  }

  a,
  a:link,
  a:visited {
    color: $dark-grey;
    font-style: italic;
  }

  @include breakpoint(between, $loan-officer-stack-breakpoint-start, $loan-officer-stack-breakpoint-end) {
    flex-wrap: wrap;
  }

  .loan-officer__image {
    align-self: flex-start; // align image to top of div
    width: 96px;
    height: 96px;
    display: block;
    flex: none;
    background-size: cover;
    border-radius: 10px;

    @include breakpoint(between, $loan-officer-stack-breakpoint-start, $loan-officer-stack-breakpoint-end) {
      text-align: center; // center image when loan officer is stacked into column
      width: 100%;
      height: 27vw;
      margin-bottom: 10px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .loan-officer__info {
    padding-left: 15px;
    width: calc(100% - 80px);

    @include breakpoint(between, $loan-officer-stack-breakpoint-start, $loan-officer-stack-breakpoint-end) {
      padding-left: 0;
      width: 100%;
    }
  }

  .loan-officer__details {

    .loan-officer-icon-wrapper {
      width: calc(100% - 30px);
      float: left;

      .loan-officer__company {
        margin: 0;
      }
    }

    .shield-wrapper {
      .hover-pop-up {
        margin-top: 13px;
      }
    }

    .hover-pop-up {
      width: 20px;
      float: left;
    }

    .hover-pop-up + .hover-pop-up {
      margin-top: 5px;
    }

    // limit width of hover pop-up body for this instance
    .hover-pop-up .hover-pop-up__body {
      @include breakpoint(between, small, medium) {
        width: 170px;
        padding: 10px;
      }
    }
  }
}

// stars under loan officer
$star-filled-in: #F2C94C;
$star-empty: #FBE8AF;
.stars {
  display: flex;
  font-size: 17px;
  margin-top: 13px;
  margin-bottom: 5px;

  .star {
    color: $star-empty;
  }

  &.stars--1 {
    .star:nth-child(-n+1) {
      color: $star-filled-in;
    }
  }

  &.stars--2 {
    .star:nth-child(-n+2) {
      color: $star-filled-in;
    }
  }

  &.stars--3 {
    .star:nth-child(-n+3) {
      color: $star-filled-in;
    }
  }

  &.stars--4 {
    .star:nth-child(-n+4) {
      color: $star-filled-in;
    }
  }

  &.stars--5 {
    .star:nth-child(-n+5) {
      color: $star-filled-in;
    }
  }
}
