// list with a green checkmark for each list item

ul.checklist {
  list-style: none;
  font-weight: 600;

  li {
    padding-left: 30px;
    position: relative;

    + li {
      margin-top: 10px;

      @include breakpoint(above, small) {
        margin-top: 20px;
      }
    }

    &::before {
      @include icomoon;

      content: '\e903';
      color: $green;
      position: absolute;
      left: 0;
      top: auto;

      @include breakpoint(above, small) {
        font-size: 24px;
      }
    }
  }
}
