.frown-image-wrapper {
  width: 35px;
  display: inline-block;
}

.profile-list {
  li {
    border-bottom: 1px solid $light-grey;
    padding: 20px 0;
    .loan-officer {
      width: 100%;
      margin-left: 0;
      display: block;
      vertical-align: middle;

      .loan-officer__info {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        .loan-officer__image {
          width: 100px;
          height: 100px;
          align-self: auto;
          display: inline-block;
          vertical-align: middle;
          margin-right: 15px;
          background-size: cover;

        }
        .loan-officer-content {
          display: inline-block;
          vertical-align: middle;

          .loan-officer__name {
            margin-bottom: 0;
          }
          .loan-officer__company {
            display: inline-block;

          }
          .hover-pop-up {

            display: inline-block;
            margin-left: 10px;
            top: 10px;

            .hover-pop-up__icon {
              width: 20px;
            }
          }
        }

      }

      .push-right {
        width: 50%;
        text-align: right;
        display: inline-block;
        vertical-align: middle;

        .company-rating, .button, .hover-pop-up {
          display: inline-block;
          vertical-align: middle;
        }

        .stars {
          display: inline-block;
          .star {
            display: inline-block;
          }
        }

        .button, .hover-pop-up {
          margin-left: 20px;
        }

        .hover-pop-up__icon {
          width: 20px;
        }
      }

      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
    &:first-child {
      border-top: 1px solid $light-grey;
    }

    @include breakpoint(below, large) {
      .loan-officer {
        .loan-officer__info {
          width: 100%;
          padding-left: 0;
          .loan-officer__image {
            width: 70px;
            height: 70px;
            float: left;
            background-size: cover;
          }

          .loan-officer-content {
            float: left;
            width: calc(100% - 85px);
            .loan-officer__name {
              font-size: 20px;
              margin-bottom: 5px;
              margin-top: 10px;
            }
            .loan-officer__company {
              font-size: 15px;
              max-width: 80%;
            }
            .hover-pop-up {
              top: 0px;
              float: right;
            }
            .hover-pop-up__icon {
              width: 20px;
            }
          }
        }

        .push-right {
          width: calc(100% - 85px);
          float: right;
          margin-top: 15px;
          .company-rating {
            float: left;
            width: 80%;

            text-align: left;
            .officer {
              .stars {
                margin-top: 0;
              }
            }
          }

          .hover-pop-up {
            min-height: 60px;
            float: right;
            @include breakpoint(below, medium) {
              min-height: 30px;
            }
          }
          .hover-pop-up__icon {
            width: 20px;
          }

          .button {
            margin: 10px 0;
            display: block;
          }
        }
      }
    }
  }
}
